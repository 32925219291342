import React, { useEffect, useState } from "react";
import {
  Container,
  ListGroup,
  Nav,
  NavDropdown,
  Navbar,
} from "react-bootstrap";
import { Avatar, Badge, Dropdown, Menu, Tooltip } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  HistoryOutlined,
  InboxOutlined,
  LogoutOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import Logo from "../../assets/Logo.png";
import "./HeaderSection.scss";
import { GetServiceNameAPI } from "../../Constants/Api/ServicesApi/ServicesApi";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../../Constants/Api/locastorageHandle";

const HeaderSection = () => {
  const token = getLocalStorageItem("UserLoginToken");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [navbarExpanded, setNavbarExpanded] = useState(false);
  const [service, setServices] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    GetServiceNameAPI()
      .then((res) => {
        setServices(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
      });
  }, []);

  const handleNavClick = (path) => {
    navigate(path);
    setNavbarExpanded(false); // Close collapse on item click
  };

  const handleRedirectService = (data) => {
    navigate(`/services/${data?.slug}`, {
      state: {
        data: data,
      },
    });
    setDropdownOpen(false);
    setNavbarExpanded(false); // Close collapse on dropdown item click
  };

  const handleMenuClick = (e) => {
    if (e.key === "profile") {
      navigate("/profile-edit");
    } else if (e.key === "logout") {
      removeLocalStorageItem("UserLoginToken");
      removeLocalStorageItem("GuestLoginToken");
      removeLocalStorageItem("Userguest_id");

      navigate("/");
    } else if (e.key === "booking-history") {
      navigate("/booking-history");
    } else if (e.key === "orders") {
      navigate("/orders");
    }
    setNavbarExpanded(false); // Close collapse on menu item click
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="profile">
        <UserOutlined /> &nbsp; Profile
      </Menu.Item>
      <Menu.Item key="booking-history">
        <HistoryOutlined /> &nbsp; Booking History
      </Menu.Item>
      <Menu.Item key="orders">
        <InboxOutlined /> &nbsp; Orders
      </Menu.Item>
      <Menu.Item key="logout">
        <LogoutOutlined /> &nbsp; Logout
      </Menu.Item>
    </Menu>
  );

  const product = useSelector(
    (state) => state.cartReducerData.products?.data?.cartItems
  );

  return (
    <div className="HeaderSection">
      <div className="TopStrip">
        <Container>
          <a href="tel:867979-0707" className="link">
            CALL US TODAY: 867-979-0707
          </a>
        </Container>
      </div>
      <Navbar
        expand="lg"
        className="bg-body-tertiary customnav"
        expanded={navbarExpanded}
        onToggle={() => setNavbarExpanded(!navbarExpanded)}
      >
        <Container>
          <Navbar.Brand
            onClick={() => handleNavClick("/")}
            className="customnav"
          >
            <img
              src={Logo}
              alt="Logo"
              className="d-inline-block align-top Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              <Nav.Link
                onClick={() => handleNavClick("/")}
                className={location.pathname === "/" ? "active" : ""}
              >
                HOME
              </Nav.Link>
              <Nav.Link
                onClick={() => handleNavClick("/about-us")}
                className={location.pathname === "/about-us" ? "active" : ""}
              >
                ABOUT US
              </Nav.Link>
              <NavDropdown
                title="SERVICES"
                id="nav-dropdown"
                show={dropdownOpen}
                onToggle={(isOpen) => setDropdownOpen(isOpen)}
              >
                <ListGroup variant="flush">
                  {service?.map((item, index) => (
                    <ListGroup.Item
                      key={index}
                      className={
                        location.pathname.includes(`/services/${item.slug}`)
                          ? "active"
                          : ""
                      }
                      onClick={() => handleRedirectService(item)}
                    >
                      <div className="itemsName">{item.name}</div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </NavDropdown>
              <Nav.Link
                onClick={() => handleNavClick("/blogs")}
                className={location.pathname === "/blogs" ? "active" : ""}
              >
                BLOG
              </Nav.Link>
              <Nav.Link
                onClick={() => handleNavClick("/product-shopping")}
                className={
                  location.pathname === "/product-shopping" ? "active" : ""
                }
              >
                SHOP
              </Nav.Link>
              {!token ? (
                <Nav.Link onClick={() => handleNavClick("/contact-us")}>
                  CONTACT US
                </Nav.Link>
              ) : (
                <Nav.Link onClick={() => handleNavClick("/contact-us")}>
                  <div className="request_quote">REQUEST A QUOTE</div>
                </Nav.Link>
              )}
              {!token ? (
                <Nav.Link onClick={() => handleNavClick("/signin")}>
                  <div className="request_quote">LOGIN</div>
                </Nav.Link>
              ) : (
                <>
                  <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    placement="bottomRight"
                  >
                    <Tooltip title="Account settings">
                      <Avatar
                        size={32}
                        icon={<UserOutlined />}
                        style={{
                          textTransform: "capitalize",
                          marginLeft: 8,
                          cursor: "pointer",
                        }}
                      >
                        B
                      </Avatar>
                    </Tooltip>
                  </Dropdown>
                  <Link to="/chekcout-order" className="link">
                    <Badge color="secondary" count={product?.length}>
                      <Avatar
                        size={32}
                        icon={<ShoppingCartOutlined />}
                        style={{
                          textTransform: "capitalize",
                          marginLeft: 8,
                          cursor: "pointer",
                        }}
                      />
                    </Badge>
                  </Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default HeaderSection;
