import React from 'react'
import { Button } from 'react-bootstrap'
import "./BlogCardComponent.scss"
import { useNavigate } from 'react-router-dom'
const BlogCardComponent = ({ Image, Title, Date, Description,blogId}) => {
  const navigate = useNavigate()
  const handleRedirectBlogDetails = (data) => {
    navigate("/blogs-details", {
      state: {
        data: data,
      }
    })
  }
  return (
    <div className='BlogCardComponent'>
      <div class="card" >
        <img class="card-img-top" src={Image} alt="Cardimagecap" crossOrigin='anonymous' height="170px" width="320px" className='Image' />
        <div class="card-body">
          <h5>{Title}</h5>
          {Date}
          <p class="card-text" className='description_text' dangerouslySetInnerHTML={{ __html: Description }} >
          </p>
          <Button className='buttontheme' onClick={() => handleRedirectBlogDetails(blogId)}>
            <span className='read_more'>

            Read More
            </span>
            </Button>
        </div>
      </div>
    </div>
  )
}
export default BlogCardComponent
