import apiClient from "../apiClient";

export const GetServiceNameAPI = async () => {
  return await apiClient.get("service/getServiceName");
};

export const ServiceDetailsAPI = async (formData) => {
  return await apiClient.post("service/getServiceFromSlug", formData);
};

export const GetAllBlogs = async (PER_PAGE, page) => {
  return await apiClient.get(`blog/getBlogs?limit=${PER_PAGE}&page=${page}`);
};

export const GetBlogsById = async (blog_id) => {
  return await apiClient.get(`blog/findBlogById/${blog_id}`);
};

export const SendMessageAPI = async (formData) => {
  return await apiClient.post("firebase/sendMessage", formData);
};

export const GetAllUserMessages = async () => {
  const config = { receiverUserId: 8 };
  return await apiClient.post("firebase/getAdminMessages", config);
};

export const PostQuery = async (formdata) => {
  return await apiClient.post("quote/createQuote", formdata);
};

export const GetConatct = async () => {
  return await apiClient.get("contactUs/getContactUs");
};

export const GetProfile = async () => {
  return await apiClient.get("user/profile");
};

export const UserEditProfileAPI = async (formdata) => {
  return await apiClient.put("user/updateUserProfile", formdata);
};

export const AddAddressAPI = async (address) => {
  const config = { address };
  return await apiClient.post("user/addNewAddress", config);
};

export const DeleteAccountApi = async () => {
  return await apiClient.delete("user/deactivate");
};
