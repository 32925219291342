// booking.js
import apiClient from "../apiClient";

export const CreateBookingWithLoginAPI = async (formData) => {
  return await apiClient.post("booking/createBooking", formData);
};

export const BookingHistoryAPI = async () => {
  return await apiClient.post("booking/getAllUserBooking");
};

export const CreateBookingWithoutLoginAPI = async (formData, headers) => {
  return await apiClient.post(`booking/createGuestBooking`, formData, {
    headers,
  });
};
