// product.js

import apiClient from "../apiClient";

export const GetProductAPI = async () => {
  return await apiClient.get('product/getAllProducts');
};

export const GetProductAPIwithToken = async () => {
  return await apiClient.get('order/findProductsInCart');
};

export const AddToCartAPI = async (formData) => {
  return await apiClient.post('order/addItemToCart', formData);
};

export const GetAllProductsInCart = async () => {
  return await apiClient.get('order/getAllProductsInCart');
};

export const RemoveProduct = async (value) => {
  const data = { cart_id: value };
  return await apiClient.delete('order/removeProduct', { data });
};

export const updateProductQuantity = async (formData) => {
  return await apiClient.post('order/updateProductQuantity', formData);
};

export const CreateOrderAPI = async (formData) => {
  return await apiClient.post('order/createOrder', formData);
};

export const GetAllOrders = async () => {
  return await apiClient.get('order/getAllUserOrders');
};
