import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Radio,
  DatePicker,
  Typography,
  Divider,
  Table,
  InputNumber,
  Col,
  Row,
  Checkbox,
} from "antd";
import "./MovingInventoryForm.scss";
import { Container } from "react-bootstrap";
const { Title } = Typography;
const inventoryData = [
  { key: "1", item: "Artificial Plants" },
  { key: "2", item: "Bar Cart" },
  { key: "3", item: "Bar Refrigerator" },
  { key: "4", item: "Bar Portable" },
  { key: "5", item: "Bar Stool" },
  { key: "6", item: "Bean Bag" },
  { key: "7", item: "Bench" },
  { key: "8", item: "Bookcase / Bookshelf" },
  { key: "9", item: "Buffet" },
  { key: "10", item: "Hutch" },
  { key: "11", item: "Cabinet, Cocktail" },
  { key: "12", item: "Cabinet, Curio" },
  { key: "13", item: "Cabinet, Large" },
  { key: "14", item: "Cabinet, Small" },
  { key: "15", item: "Chair" },
  { key: "16", item: "Chair, Arm" },
  { key: "17", item: "Chair, Lounge" },
  { key: "18", item: "Chair, Desk" },
  { key: "19", item: "Chair, Occasional" },
  { key: "20", item: "Chair, Overstuffed" },
  { key: "21", item: "Chair, Recliner" },
  { key: "22", item: "Chair, Rocker" },
  { key: "23", item: "Chair, Wingback" },
  { key: "24", item: "Clocks" },
  { key: "25", item: "Clock, Grandfather" },
  { key: "26", item: "Desk" },
  { key: "27", item: "DVD / VCR" },
  { key: "28", item: "Electronic Keyboard" },
  { key: "29", item: "Electronics" },
  { key: "30", item: "Entertainment Center" },
  { key: "31", item: "Fireplace, Electric" },
  { key: "32", item: "Fireplace Equipment" },
  { key: "33", item: "Footstool" },
  { key: "34", item: "Game System" },
  { key: "35", item: "Hat / Coat Rack" },
  { key: "36", item: "Lamp, Floor" },
  { key: "37", item: "Lamp, Table" },
  { key: "38", item: "Organ" },
  { key: "39", item: "Ottoman" },
  { key: "40", item: "Painting / Picture / Art Work" },
  { key: "41", item: "Piano" },
  { key: "42", item: "Piano Bench" },
  { key: "43", item: "Rug" },
  { key: "44", item: "Sofa" },
  { key: "45", item: "Sofa,Futon" },
  { key: "46", item: "Sofa,Hide-A-Bed" },
  { key: "47", item: "Sofa,love Seat" },
  { key: "48", item: "Sofa,Section" },
  { key: "49", item: "Speaker" },
  { key: "50", item: "Table, Coffee" },
  { key: "51", item: "Table, Drop" },
  { key: "52", item: "Table, End" },
  { key: "53", item: "Table, Glass / Marble" },
  { key: "54", item: "Table, Nestled Set" },
  { key: "55", item: "TV Flat Screen Over 55" },
  { key: "56", item: "TV Flat Screen Under 54" },
  { key: "57", item: "TV Plasma" },
  { key: "58", item: "TV Regular Large Back" },
  { key: "59", item: "TV Tray Set" },
  { key: "60", item: "Wall Unit" },
];
const inventoryColumns = [
  {
    title: "Item Name",
    dataIndex: "item",
    key: "item",
    render: (_, record) => <span>{record.item}</span>, // Displays the item name
  },
  {
    title: "Qty.",
    dataIndex: "quantity",
    key: "quantity",
    render: (_, record) => (
      <Form.Item
        name={["inventory", record.key, "quantity"]}
        style={{ margin: 0 }}
      >
        <InputNumber min={0} placeholder="" />
      </Form.Item>
    ),
  },
  {
    title: "Dimensions",
    dataIndex: "dimensions",
    key: "dimensions",
    children: [
      {
        title: "Length",
        dataIndex: "length",
        key: "length",
        render: (_, record) => (
          <Form.Item
            name={["inventory", record.key, "length"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
      {
        title: "Width",
        dataIndex: "width",
        key: "width",
        render: (_, record) => (
          <Form.Item
            name={["inventory", record.key, "width"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
      {
        title: "Height",
        dataIndex: "height",
        key: "height",
        render: (_, record) => (
          <Form.Item
            name={["inventory", record.key, "height"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
    ],
  },
  {
    title: "Notes",
    dataIndex: "notes",
    key: "notes",
    render: (_, record) => (
      <Form.Item
        name={["inventory", record.key, "notes"]}
        style={{ margin: 0 }}
      >
        <Input placeholder="" />
      </Form.Item>
    ),
  },
];
const bedroomData = [
  { key: "1", item: "Bed, Bunk" },
  { key: "2", item: "Bed, Child" },
  { key: "3", item: "Bed, Twin" },
  { key: "4", item: "Bed, Full" },
  { key: "5", item: "Bed, Queen" },
  { key: "6", item: "Bed King" },
  { key: "7", item: "Bed, CA King" },
  { key: "8", item: "Bed, Foot-board" },
  { key: "9", item: "Bed, Frame" },
  { key: "10", item: "Bed, Headboard" },
  { key: "11", item: "Bed, Roll-away" },
  { key: "12", item: "Cabinet, Jewelry" },
  { key: "13", item: "Chair" },
  { key: "14", item: "Chest" },
  { key: "15", item: "Chest of Drawers" },
  { key: "16", item: "Chest, Armoire" },
  { key: "17", item: "Chest, Lingerie" },
  { key: "18", item: "Chest, Gentleman" },
  { key: "19", item: "Dresser" },
  { key: "20", item: "Dresser, Bureau" },
  { key: "21", item: "Dresser, Mirror" },
  { key: "22", item: "Dresser, Vanity" },
  { key: "23", item: "Table, Bedside" },
];
const bedroomColumns = [
  {
    title: "Item Name",
    dataIndex: "item",
    key: "item",
    render: (_, record) => <span>{record.item}</span>, // Displays the item name
  },
  {
    title: "Qty.",
    dataIndex: "quantity",
    key: "quantity",
    render: (_, record) => (
      <Form.Item
        name={["bedroom", record.key, "quantity"]}
        style={{ margin: 0 }}
      >
        <InputNumber min={0} placeholder="" />
      </Form.Item>
    ),
  },
  {
    title: "Dimensions",
    dataIndex: "dimensions",
    key: "dimensions",
    children: [
      {
        title: "Length",
        dataIndex: "length",
        key: "length",
        render: (_, record) => (
          <Form.Item
            name={["bedroom", record.key, "length"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
      {
        title: "Width",
        dataIndex: "width",
        key: "width",
        render: (_, record) => (
          <Form.Item
            name={["bedroom", record.key, "width"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
      {
        title: "Height",
        dataIndex: "height",
        key: "height",
        render: (_, record) => (
          <Form.Item
            name={["bedroom", record.key, "height"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
    ],
  },
  {
    title: "Notes",
    dataIndex: "notes",
    key: "notes",
    render: (_, record) => (
      <Form.Item name={["bedroom", record.key, "notes"]} style={{ margin: 0 }}>
        <Input placeholder="" />
      </Form.Item>
    ),
  },
];
const CHILD_NURSERY_Data = [
  { key: "1", item: "Bed, Child" },
  { key: "2", item: "Car Seat" },
  { key: "3", item: "Chair, Child" },
  { key: "4", item: "Chair, Rocker" },
  { key: "5", item: "Changing Table" },
  { key: "6", item: "Chest, Toy" },
  { key: "7", item: "Crib" },
  { key: "8", item: "Desk, Child" },
  { key: "9", item: "Dresser" },
  { key: "10", item: "Playpen" },
  { key: "11", item: "Stroller" },
  { key: "12", item: "Table, Child" },
  { key: "13", item: "Boxes of Books" },
  { key: "14", item: "Boxes of Toys" },
];
const CHILD_NURSERY_Columns = [
  {
    title: "Item Name",
    dataIndex: "item",
    key: "item",
    render: (_, record) => <span>{record.item}</span>, // Displays the item name
  },
  {
    title: "Qty.",
    dataIndex: "quantity",
    key: "quantity",
    render: (_, record) => (
      <Form.Item
        name={["child_nursery", record.key, "quantity"]}
        style={{ margin: 0 }}
      >
        <InputNumber min={0} placeholder="" />
      </Form.Item>
    ),
  },
  {
    title: "Dimensions",
    dataIndex: "dimensions",
    key: "dimensions",
    children: [
      {
        title: "Length",
        dataIndex: "length",
        key: "length",
        render: (_, record) => (
          <Form.Item
            name={["child_nursery", record.key, "length"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
      {
        title: "Width",
        dataIndex: "width",
        key: "width",
        render: (_, record) => (
          <Form.Item
            name={["child_nursery", record.key, "width"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
      {
        title: "Height",
        dataIndex: "height",
        key: "height",
        render: (_, record) => (
          <Form.Item
            name={["child_nursery", record.key, "height"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
    ],
  },
  {
    title: "Notes",
    dataIndex: "notes",
    key: "notes",
    render: (_, record) => (
      <Form.Item
        name={["child_nursery", record.key, "notes"]}
        style={{ margin: 0 }}
      >
        <Input placeholder="" />
      </Form.Item>
    ),
  },
];
const OFFICE_STUDY_Data = [
  { key: "1", item: "Board - Black/White/Chalk" },
  { key: "2", item: "Bookcase or Bookshelf" },
  { key: "3", item: "Computer" },
  { key: "4", item: "Copier/Printer/Scanner" },
  { key: "5", item: "Credenza" },
  { key: "6", item: "Desk" },
  { key: "7", item: "Desk, Chair" },
  { key: "8", item: "Desk, Child" },
  { key: "9", item: "File Cabinet" },
];
const OFFICE_STUDY_Columns = [
  {
    title: "Item Name",
    dataIndex: "item",
    key: "item",
    render: (_, record) => <span>{record.item}</span>, // Displays the item name
  },
  {
    title: "Qty.",
    dataIndex: "quantity",
    key: "quantity",
    render: (_, record) => (
      <Form.Item
        name={["office_study", record.key, "quantity"]}
        style={{ margin: 0 }}
      >
        <InputNumber min={0} placeholder="" />
      </Form.Item>
    ),
  },
  {
    title: "Dimensions",
    dataIndex: "dimensions",
    key: "dimensions",
    children: [
      {
        title: "Length",
        dataIndex: "length",
        key: "length",
        render: (_, record) => (
          <Form.Item
            name={["office_study", record.key, "length"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
      {
        title: "Width",
        dataIndex: "width",
        key: "width",
        render: (_, record) => (
          <Form.Item
            name={["office_study", record.key, "width"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
      {
        title: "Height",
        dataIndex: "height",
        key: "height",
        render: (_, record) => (
          <Form.Item
            name={["office_study", record.key, "height"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
    ],
  },
  {
    title: "Notes",
    dataIndex: "notes",
    key: "notes",
    render: (_, record) => (
      <Form.Item
        name={["office_study", record.key, "notes"]}
        style={{ margin: 0 }}
      >
        <Input placeholder="" />
      </Form.Item>
    ),
  },
];
const DINING_ROOM_Data = [
  { key: "1", item: "Art Work" },
  { key: "2", item: "Buffet / Hutch" },
  { key: "3", item: "Cabinet, China" },
  { key: "4", item: "Cabinet, Corner" },
  { key: "5", item: "Chair, Dining" },
  { key: "6", item: "Chair, Dining With Arm" },
  { key: "7", item: "Mirrorr" },
  { key: "8", item: "Rug" },
  { key: "9", item: "Table, Dining" },
  { key: "10", item: "Table, Removable Glass" },
  { key: "11", item: "Table, Leaf" },
  { key: "12", item: "Table, Small" },
  { key: "13", item: "Tea Cart" },
  { key: "14", item: "Sideboard" },
];
const DINING_ROOM_Columns = [
  {
    title: "Item Name",
    dataIndex: "item",
    key: "item",
    render: (_, record) => <span>{record.item}</span>, // Displays the item name
  },
  {
    title: "Qty.",
    dataIndex: "quantity",
    key: "quantity",
    render: (_, record) => (
      <Form.Item
        name={["dining_room", record.key, "quantity"]}
        style={{ margin: 0 }}
      >
        <InputNumber min={0} placeholder="" />
      </Form.Item>
    ),
  },
  {
    title: "Dimensions",
    dataIndex: "dimensions",
    key: "dimensions",
    children: [
      {
        title: "Length",
        dataIndex: "length",
        key: "length",
        render: (_, record) => (
          <Form.Item
            name={["dining_room", record.key, "length"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
      {
        title: "Width",
        dataIndex: "width",
        key: "width",
        render: (_, record) => (
          <Form.Item
            name={["dining_room", record.key, "width"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
      {
        title: "Height",
        dataIndex: "height",
        key: "height",
        render: (_, record) => (
          <Form.Item
            name={["dining_room", record.key, "height"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
    ],
  },
  {
    title: "Notes",
    dataIndex: "notes",
    key: "notes",
    render: (_, record) => (
      <Form.Item
        name={["dining_room", record.key, "notes"]}
        style={{ margin: 0 }}
      >
        <Input placeholder="" />
      </Form.Item>
    ),
  },
];
const KITCHEN_Data = [
  { key: "1", item: "Baker’s Rack" },
  { key: "2", item: "Bar Stool" },
  { key: "3", item: "Cabinet" },
  { key: "4", item: "Chair" },
  { key: "5", item: "Chair, High" },
  { key: "6", item: "Dishwasher" },
  { key: "7", item: "Freezer" },
  { key: "8", item: "Microwave" },
  { key: "9", item: "Microwave Stand" },
  { key: "10", item: "Range / Stove" },
  { key: "11", item: "Refrigerator" },
  { key: "12", item: "Serving Cart" },
  { key: "13", item: "Table" },
  { key: "14", item: "Table, Removable Glass" },
];
const KITCHEN_Columns = [
  {
    title: "Item Name",
    dataIndex: "item",
    key: "item",
    render: (_, record) => <span>{record.item}</span>, // Displays the item name
  },
  {
    title: "Qty.",
    dataIndex: "quantity",
    key: "quantity",
    render: (_, record) => (
      <Form.Item
        name={["kitchen", record.key, "quantity"]}
        style={{ margin: 0 }}
      >
        <InputNumber min={0} placeholder="" />
      </Form.Item>
    ),
  },
  {
    title: "Dimensions",
    dataIndex: "dimensions",
    key: "dimensions",
    children: [
      {
        title: "Length",
        dataIndex: "length",
        key: "length",
        render: (_, record) => (
          <Form.Item
            name={["kitchen", record.key, "length"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
      {
        title: "Width",
        dataIndex: "width",
        key: "width",
        render: (_, record) => (
          <Form.Item
            name={["kitchen", record.key, "width"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
      {
        title: "Height",
        dataIndex: "height",
        key: "height",
        render: (_, record) => (
          <Form.Item
            name={["kitchen", record.key, "height"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
    ],
  },
  {
    title: "Notes",
    dataIndex: "notes",
    key: "notes",
    render: (_, record) => (
      <Form.Item name={["kitchen", record.key, "notes"]} style={{ margin: 0 }}>
        <Input placeholder="" />
      </Form.Item>
    ),
  },
];
const LAUNDRY_ROOM_Data = [
  { key: "1", item: "Clothes Hamper" },
  { key: "2", item: "Clothes Rack" },
  { key: "3", item: "Ironing Board" },
  { key: "4", item: "Mops / Brooms" },
  { key: "5", item: "Stool" },
  { key: "6", item: "Vacuum Cleaner" },
  { key: "7", item: "Washing Machine" },
  { key: "8", item: "Dryer" },
];
const LAUNDRY_ROOM_Columns = [
  {
    title: "Item Name",
    dataIndex: "item",
    key: "item",
    render: (_, record) => <span>{record.item}</span>, // Displays the item name
  },
  {
    title: "Qty.",
    dataIndex: "quantity",
    key: "quantity",
    render: (_, record) => (
      <Form.Item
        name={["laundary_room", record.key, "quantity"]}
        style={{ margin: 0 }}
      >
        <InputNumber min={0} placeholder="" />
      </Form.Item>
    ),
  },
  {
    title: "Dimensions",
    dataIndex: "dimensions",
    key: "dimensions",
    children: [
      {
        title: "Length",
        dataIndex: "length",
        key: "length",
        render: (_, record) => (
          <Form.Item
            name={["laundary_room", record.key, "length"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
      {
        title: "Width",
        dataIndex: "width",
        key: "width",
        render: (_, record) => (
          <Form.Item
            name={["laundary_room", record.key, "width"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
      {
        title: "Height",
        dataIndex: "height",
        key: "height",
        render: (_, record) => (
          <Form.Item
            name={["laundary_room", record.key, "height"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
    ],
  },
  {
    title: "Notes",
    dataIndex: "notes",
    key: "notes",
    render: (_, record) => (
      <Form.Item
        name={["laundary_room", record.key, "notes"]}
        style={{ margin: 0 }}
      >
        <Input placeholder="" />
      </Form.Item>
    ),
  },
];
const MISCELLANEOUS_Data = [
  { key: "1", item: "Air Conditioner, Portable" },
  { key: "2", item: "Animal Cage" },
  { key: "3", item: "Aquarium" },
  { key: "4", item: "Artificial Plant" },
  { key: "5", item: "Barbecue" },
  { key: "6", item: "Bird Bath" },
  { key: "7", item: "Boat on Trailer" },
  { key: "8", item: "Cabinet" },
  { key: "9", item: "Camper on Trailer" },
  { key: "10", item: "Camping Gear - List" },
  { key: "11", item: "Canoe / Kayak" },
  { key: "12", item: "Card Table" },
  { key: "13", item: "Cat Tower" },
  { key: "14", item: "Chair" },
  { key: "15", item: "Cooler" },
  { key: "16", item: "Cot" },
  { key: "17", item: "Dog House" },
  { key: "18", item: "Fan" },
  { key: "19", item: "Farm Equipment - List" },
  { key: "21", item: "Footlocker" },
  { key: "22", item: "Garden Hose" },
  { key: "23", item: "Garden Tools" },
  { key: "24", item: "Glider" },
  { key: "25", item: "Gun Cabinet" },
  { key: "26", item: "Gym Equipment - List" },
  { key: "28", item: "Ladder" },
  { key: "29", item: "Lawn Mower" },
  { key: "30", item: "Motor Bike, Motorcycle" },
  { key: "31", item: "Partition / Folding Screen" },
  { key: "32", item: "Pedestal" },
  { key: "33", item: "Picnic Table" },
  { key: "34", item: "Ping Pong / Pool Table" },
  { key: "35", item: "Plastic Tote" },
  { key: "36", item: "Pots, Empty for Plants" },
  { key: "37", item: "Power Tools - List" },
  { key: "38", item: "Power Tool Stand" },
  { key: "39", item: "Rocker / Swing / Glider" },
  { key: "40", item: "Safe" },
  { key: "41", item: "Saw Horse" },
  { key: "42", item: "Scuba Gear - List" },
  { key: "43", item: "Sculpture" },
  { key: "44", item: "Sewing Machine" },
  { key: "45", item: "Sewing Machine Cabinet" },
  { key: "46", item: "Shelving" },
  { key: "47", item: "Snow Blower" },
  { key: "48", item: "Spa / Hot Tub" },
  { key: "49", item: "Stand" },
  { key: "50", item: "Surfboard / Paddle-board" },
  { key: "51", item: "Snowboard / Ski Equipment" },
  { key: "52", item: "Suitcase" },
  { key: "53", item: "Table" },
  { key: "54", item: "Tire" },
  { key: "55", item: "Tool Chest" },
  { key: "56", item: "Shed" },
  { key: "57", item: "Trailer" },
  { key: "58", item: "Trash Can" },
  { key: "59", item: "Trunk" },
  { key: "60", item: "Wagon" },
  { key: "61", item: "Wheelbarrow" },
  { key: "62", item: "Work Bench" },
  { key: "63", item: "Weights - Itemize" },
  { key: "64", item: "Weight Bench" },
];
const MISCELLANEOUS_Columns = [
  {
    title: "Item Name",
    dataIndex: "item",
    key: "item",
    render: (_, record) => <span>{record.item}</span>, // Displays the item name
  },
  {
    title: "Qty.",
    dataIndex: "quantity",
    key: "quantity",
    render: (_, record) => (
      <Form.Item
        name={["miscellaneous", record.key, "quantity"]}
        style={{ margin: 0 }}
      >
        <InputNumber min={0} placeholder="" />
      </Form.Item>
    ),
  },
  {
    title: "Dimensions",
    dataIndex: "dimensions",
    key: "dimensions",
    children: [
      {
        title: "Length",
        dataIndex: "length",
        key: "length",
        render: (_, record) => (
          <Form.Item
            name={["miscellaneous", record.key, "length"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
      {
        title: "Width",
        dataIndex: "width",
        key: "width",
        render: (_, record) => (
          <Form.Item
            name={["miscellaneous", record.key, "width"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
      {
        title: "Height",
        dataIndex: "height",
        key: "height",
        render: (_, record) => (
          <Form.Item
            name={["miscellaneous", record.key, "height"]}
            style={{ margin: 0 }}
          >
            <InputNumber min={0} placeholder="X" />
          </Form.Item>
        ),
      },
    ],
  },
  {
    title: "Notes",
    dataIndex: "notes",
    key: "notes",
    render: (_, record) => (
      <Form.Item
        name={["miscellaneous", record.key, "notes"]}
        style={{ margin: 0 }}
      >
        <Input placeholder="" />
      </Form.Item>
    ),
  },
];
const items = [
  { label: "Dishpack", dimensions: "18x18x27" },
  { label: "Small: 1.5", dimensions: "17x12x12" },
  { label: "Small With Books", dimensions: "17x12x12" },
  { label: "Medium: 3.0", dimensions: "18x18x16" },
  { label: "Large: 4.5", dimensions: "18x18x24" },
  { label: "Extra Large: 6.0", dimensions: "22x22x21" },
  { label: "Mirror/Picture/Art", dimensions: "38x4x60" },
  { label: "Lamp Box", dimensions: "" },
  { label: "Lamp Shade Box", dimensions: "" },
  { label: "Mattress", dimensions: "" },
  { label: "Box Spring", dimensions: "" },
  { label: "Wardrobe", dimensions: "21 Inches of Hanging Space" },
];

const MovingInventoryForm = () => {
  // Function to handle form submission
  const onFinish = (values: any) => {
    console.log("Form values: ", values);
  };

  const [checkedValues, setCheckedValues] = useState(
    items.map(() => ({ byOwner: false, byCarrier: false }))
  );

  const handleCheckboxChange = (index, field, checked) => {
    const updatedCheckedValues = [...checkedValues];
    updatedCheckedValues[index][field] = checked;
    setCheckedValues(updatedCheckedValues);

    // Log the updated values to the console
    console.log("Updated checked values:", updatedCheckedValues);
  };
  return (
    <Container>
      <div className="moving-inventory-form">
        <header className="form-header">
          <Title className="Heading">Moving Inventory</Title>
        </header>
        <Form layout="vertical" autoComplete="off" onFinish={onFinish}>
          <Form.Item label="Names of All Customers" name="customers">
            <Input />
          </Form.Item>
          <Form.Item label="Phone Contact">
            <Form.Item
              label="Cell"
              name="cell"
              style={{ display: "inline-block", width: "calc(33% - 8px)" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Home"
              name="home"
              style={{
                display: "inline-block",
                width: "calc(33% - 8px)",
                margin: "0 8px",
              }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Other"
              name="other"
              style={{ display: "inline-block", width: "calc(33% - 8px)" }}
            >
              <Input />
            </Form.Item>
          </Form.Item>
          <Form.Item label="Email Address" name="email_address">
            <Input />
          </Form.Item>
          <Form.Item label="Origin Address" name="origin_address">
            <Input />
          </Form.Item>
          <Form.Item label="Extra Origin Address" name="extra_origin_address">
            <Input />
          </Form.Item>
          <Form.Item label="Storage Pick Up">
            <Radio.Group name="storagePickUp">
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Delivery Address" name="delivery_address">
            <Input />
          </Form.Item>
          <Form.Item
            label="Extra Delivery Address"
            name="extra_delivery_address"
          >
            <Input />
          </Form.Item>
          <Form.Item label="Storage Delivery">
            <Radio.Group name="storageDelivery">
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="At Origin: Will an 80’ tractor trailer be able to legally park and maneuver safely at your location?">
            <Radio.Group name="originTruckAccess">
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="At Delivery: Will an 80’ tractor trailer be able to legally park and maneuver safely at your location?">
            <Radio.Group name="deliveryTruckAccess">
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Origin: Gated/Community Name/Code"
            name="originCommunityCode"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Destination: Gated/Community Name/Code"
            name="destinationCommunityCode"
          >
            <Input />
          </Form.Item>
          <div className="moving-options">
            <div className="option-group">
              <Title level={4}>Origin</Title>
              <Form.Item label="Elevator">
                <Radio.Group name="originElevator">
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="Stairs">
                <Radio.Group name="originStairs">
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className="option-group">
              <Title level={4}>Destination</Title>
              <Form.Item label="Elevator">
                <Radio.Group name="destinationElevator">
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="Stairs">
                <Radio.Group name="destinationStairs">
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
          <Form.Item label="Is this an International shipment?">
            <Radio.Group name="internationalShipment">
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Preferred Move Dates"
            name="moveDate"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
          {/* Living Room Inventory Section */}
          <Title className="Heading_small">Living Room</Title>
          <Divider className="divider_Color" />
          <Table
            dataSource={inventoryData}
            columns={inventoryColumns}
            pagination={false}
            bordered
          />
          <Title className="Heading_small">BEDROOMS</Title>
          <Divider className="divider_Color" />
          <Table
            dataSource={bedroomData}
            columns={bedroomColumns}
            pagination={false}
            bordered
          />
          <Title className="Heading_small">CHILD/NURSERY</Title>
          <Divider className="divider_Color" />
          <Table
            dataSource={CHILD_NURSERY_Data}
            columns={CHILD_NURSERY_Columns}
            pagination={false}
            bordered
          />{" "}
          <Title className="Heading_small">OFFICE/STUDY</Title>
          <Divider className="divider_Color" />
          <Table
            dataSource={OFFICE_STUDY_Data}
            columns={OFFICE_STUDY_Columns}
            pagination={false}
            bordered
          />{" "}
          <Title className="Heading_small">DINING ROOM</Title>
          <Divider className="divider_Color" />
          <Table
            dataSource={DINING_ROOM_Data}
            columns={DINING_ROOM_Columns}
            pagination={false}
            bordered
          />
          <Title className="Heading_small">KITCHEN</Title>
          <Divider className="divider_Color" />
          <Table
            dataSource={KITCHEN_Data}
            columns={KITCHEN_Columns}
            pagination={false}
            bordered
          />
          <Title className="Heading_small">LAUNDRY ROOM</Title>
          <Divider className="divider_Color" />
          <Table
            dataSource={LAUNDRY_ROOM_Data}
            columns={LAUNDRY_ROOM_Columns}
            pagination={false}
            bordered
          />
          <Title className="Heading_small">MISCELLANEOUS</Title>
          <Divider className="divider_Color" />
          <Table
            dataSource={MISCELLANEOUS_Data}
            columns={MISCELLANEOUS_Columns}
            pagination={false}
            bordered
          />
          <div className="inventory-table">
            <Row className="inventory-header">
              <Col span={8}>{/* <Title level={5}>Item</Title> */}</Col>
              <Col span={8}>
                <Title level={5}>By Owner</Title>
              </Col>
              <Col span={8}>
                <Title level={5}>By Carrier</Title>
              </Col>
            </Row>
            {items.map((item, index) => (
              <Row key={index} className="inventory-row">
                <Col span={8} className="inventory-item">
                  <div>{item.label}</div>
                  {item.dimensions && (
                    <div className="dimensions">{item.dimensions}</div>
                  )}
                </Col>
                <Col span={8}>
                  <Checkbox
                    checked={checkedValues[index].byOwner}
                    onChange={(e) =>
                      handleCheckboxChange(index, "byOwner", e.target.checked)
                    }
                  />
                </Col>
                <Col span={8}>
                  <Checkbox
                    checked={checkedValues[index].byCarrier}
                    onChange={(e) =>
                      handleCheckboxChange(index, "byCarrier", e.target.checked)
                    }
                  />
                </Col>
              </Row>
            ))}
          </div>
          Special Notes to the Relocation Consultant
          <Form.Item style={{ marginTop: "20px" }}>
            <Button htmlType="submit" className="buttontheme">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Container>
  );
};
export default MovingInventoryForm;
