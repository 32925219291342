// chatSlice.js

import { createSlice } from "@reduxjs/toolkit";
import {
  GetAllUserMessages,
  SendMessageAPI,
} from "../Constants/Api/ServicesApi/ServicesApi";
// import { GetAllUserMessages, SendMessageAPI } from '../../Constants/Api/Api';

const initialState = {
  messages: [],
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    fetchMessagesSuccess(state, action) {
      state.messages = action.payload;
    },
    sendMessageSuccess(state, action) {
      state.messages.push(action.payload);
    },
  },
});

export const { fetchMessagesSuccess, sendMessageSuccess } = chatSlice.actions;

export const fetchMessages = () => async (dispatch) => {
  try {
    const res = await GetAllUserMessages();
    dispatch(fetchMessagesSuccess(res?.data?.data || []));
  } catch (error) {
    console.error("Error fetching messages:", error);
  }
};

export const sendMessage = (inputValue) => async (dispatch) => {
  try {
    if (inputValue.trim() !== "") {
      const newMessage = {
        message: inputValue,
        source: "RECEIVER",
        read: false,
        timestamp: Date.now(),
      };
      dispatch(sendMessageSuccess(newMessage));
      SendMessageAPI({ message: inputValue, receiverUserId: 8 })
        .then((res) => {})
        .catch((error) => {
          console.error("Error sending message:", error);
        });
    }
  } catch (error) {
    console.error("Error sending message:", error);
  }
};

export default chatSlice.reducer;
