import React, { useState } from "react";
import { Container } from "react-bootstrap";
import "./signup.scss";
import { Button, Checkbox, Form, Input, message, Spin } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { UserRegisterAPI } from "../../Constants/Api/AuthApi/AuthApi";

const SignUp = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    let formData = {
      name: values.username,
      email: values.useremail,
      mobile: values.usermobile,
      password: values.password,
      confirm_password: values.confirm,
    };

    try {
      setLoading(true);
      const res = await UserRegisterAPI(formData);
      message.success(res?.data?.message);
      navigate("/verifyotp", {
        state: {
          email: values.useremail,
        },
      });
    } catch (error) {
      message.error(error?.response?.data?.message);
      console.log(error, "error");
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {};
  return (
    <Container className="Signup">
      <div className="Main">
        <h3>Sign Up</h3>
        <div className="form_inner">
          <Spin spinning={loading}>
            <Form
              name="basic"
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="User Name"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="User Email"
                name="useremail"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid Email!",
                  },
                  {
                    required: true,
                    message: "Please input your Email!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="User Mobile"
                name="usermobile"
                rules={[
                  {
                    required: true,
                    message: "Please input your usermobile!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The new password that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" className="buttontheme">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Spin>
          <div className="bottom_text">
            <div>
              Already have an account?<Link to="/signin">Sign in</Link>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default SignUp;
