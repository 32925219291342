import React, { useRef } from 'react';
import { Carousel } from 'antd';
import './Carousel.scss'; // Import your custom styles here
import { FaLongArrowAltLeft, FaLongArrowAltRight } from 'react-icons/fa';
import HeadingImage from "../../assets/heading-border.png";
const CarouselComponent = () => {
  const carouselRef = useRef(null);
  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };
  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };
  return (
    <div className="carousel-wrapper">
      <Carousel ref={carouselRef} dots={false}>
        <div className='sliderimages1'>
          <div className='text-center'>
            <h4>NUNAVUT<dd>MOVING &amp; SERVICES</dd></h4>
            <div className='headingimage'>
              <img src={HeadingImage} alt='' />
            </div>
            <h6 class="hidden-xs">your satisfaction is our <span>PRIORITY</span></h6>
            <p class="hidden-xs">Nunavut Moving &amp; Services uses state of the art equipment to move your furniture safely. We protect your walls and furniture. We move heavy safe, heavy filling cabinets at the fraction of time and in a safe way.</p>
            <aside><a href="tel:8679790707" className="link">Call Us Today</a></aside>
          </div>
        </div>
        <div className='sliderimages2'>
          <div className='text-center'>
            <h4>NUNAVUT<dd>MOVING &amp; SERVICES1</dd></h4>
            <div className='headingimage'>
              <img src={HeadingImage} alt='' />
            </div>
            <h6 class="hidden-xs">your satisfaction is our <span>PRIORITY</span></h6>
            <p class="hidden-xs">Nunavut Moving &amp; Services uses state of the art equipment to move your furniture safely. We protect your walls and furniture. We move heavy safe, heavy filling cabinets at the fraction of time and in a safe way.</p>
            <aside><a href="tel:8679790707" className="link">Call Us Today</a></aside>
          </div>
        </div>
        <div className='sliderimages3'>
          <div className='text-center'>
            <h4>NUNAVUT<dd>MOVING &amp; SERVICES2</dd></h4>
            <div className='headingimage'>
              <img src={HeadingImage} alt='' />
            </div>
            <h6 class="hidden-xs">your satisfaction is our <span>PRIORITY</span></h6>
            <p class="hidden-xs">Nunavut Moving &amp; Services uses state of the art equipment to move your furniture safely. We protect your walls and furniture. We move heavy safe, heavy filling cabinets at the fraction of time and in a safe way.</p>
            <aside><a href="tel:8679790707" className="link">Call Us Today</a></aside>
          </div>
        </div>
      </Carousel>
      <div className="carousel-arrows">
        <button className="prev-button" onClick={handlePrev}><FaLongArrowAltLeft  size={30}/></button>
        <button className="next-button" onClick={handleNext}><FaLongArrowAltRight size={30} /></button>
      </div>
    </div>
  );
};
export default CarouselComponent;
