import React, { useState } from "react";
import "./signin.scss";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Input, message, Spin } from "antd";
import { Container } from "react-bootstrap";
import { UserLoginAPI } from "../../Constants/Api/AuthApi/AuthApi";
import { removeLocalStorageItem } from "../../Constants/Api/locastorageHandle";

const Signin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);

    let formData = {
      email: values.useremail,
      password: values.password,
    };
    const headers={
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,

    }
    UserLoginAPI(formData,headers)
      .then((res) => {
        message.success(res?.data?.message);
        setTimeout(() => {
          const Userguest = res?.data?.data?.guest_id ? null : "LoginUser";
          const token = res?.data?.data?.tokens?.access.token;
          removeLocalStorageItem("UserLoginToken");
          removeLocalStorageItem("GuestLoginToken");
          localStorage.setItem("UserLoginToken", token);
          removeLocalStorageItem("Userguest_id");
          localStorage.setItem("Userguest_id", Userguest);
          navigate("/");
          setLoading(false);
        }, 1000);
      })
      .catch((error) => {
        console.log(error, "error");
        setTimeout(() => {
          if (
            error?.response?.data?.status === 400 &&
            error?.response?.data?.message ===
              "User is not verified yet. Please verify your OTP first."
          ) {
            navigate("/verifyotp", {
              state: {
                email: values.useremail,
              },
            });
          }
        }, 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onFinishFailed = (errorInfo) => {};

  return (
    <>
      <Container className="Signin">
        <div className="Main">
          <h3>SignIn</h3>
          <div className="form_inner">
            <Spin spinning={loading}>
              <Form
                name="basic"
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="User Email"
                  name="useremail"
                  rules={[
                    {
                      required: true,
                      message: "Please input your useremail!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item>
                  <p
                    onClick={() => {
                      navigate("/forgot-password");
                    }}
                    className="forgotpasswordtext"
                  >
                    Forgot password?
                  </p>
                  <Button htmlType="submit" className="buttontheme">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </div>
          <div className="bottom_text">
            <div>
              I’m a new user.? <Link to="/signup">Sign Up</Link>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};
export default Signin;
