import React, { useEffect, useState } from "react";
import "./HomeContact.scss";
import { PiMapPinFill } from "react-icons/pi";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Form, Input, Select, Spin, message } from "antd";

import TextArea from "antd/es/input/TextArea";
import { Button, Col, Container, Row } from "react-bootstrap";
import {
  GetConatct,
  GetServiceNameAPI,
  PostQuery,
} from "../../Constants/Api/ServicesApi/ServicesApi";
const HomeContact = () => {
  const [service, setService] = useState();
  const [address, setAddress] = useState();
  const [loading,setLoading]=useState(false)
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [form] = Form.useForm();
  useEffect(() => {
    GetServiceNameAPI()
      .then((res) => {
        setService(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching service names:", error);
      });
  }, []);
  const handleServiceSelect = (value) => {
    setSelectedServiceId(value);
  };
  const onFinish = (values) => {
    setLoading(true); // Set loading to true on submit
    const formData = {
      name: values.username,
      email: values.useremail,
      contact_number: values.usermobile,
      subject: values.subject,
      messages: values.message,
      service_id: selectedServiceId,
    };

    PostQuery(formData)
      .then((res) => {
        message.success(
          res?.data?.message === "New quote Data Created Successfully."
            ? "Request Sent"
            : null
        );
        form.resetFields(); // Reset the form fields after submission
      })
      .catch((e) => {
        console.log(e, "error");
      })
      .finally(() => {
        setLoading(false); // Reset loading state after the request is complete
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };
  useEffect(() => {
    GetConatct()
      .then((res) => {
        setAddress(res?.data?.data[0]);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);
  return (
    <Container>
      <Row className="HomeContact">
        <Col className="left">
          <h3 className="h3heading">LET'S TALK</h3>
          <p className="para">
            We welcome your questions or comments. Feel free to contact us by
            phone at the number below or by e-mail.
          </p>
          <div className="detail">
            <div className="icon">
              <PiMapPinFill size={30} />
            </div>
            <div className="text">
              <strong>Our Address</strong>
              <p>
                <span>Nunavut Moving &amp; Services</span>
                <br />
                Building 1088 C8 P.O. BOX 62, IQALUIT, NU X0A 0H0
              </p>
            </div>
          </div>
          <div className="detail">
            <div className="icon">
              <FaPhoneAlt size={30} />
            </div>
            <div className="text">
              <strong>OUR CONTACT</strong>
              <p>
                Phone: <a href="tel:(867) 979-0707">(867) 979-0707</a>
              </p>
            </div>
          </div>
          <div className="detail">
            <div className="icon">
              <MdEmail size={30} />
            </div>
            <div className="text">
              <strong>OUR EMAIL ADDRESS</strong>
              <p>
                Email:{" "}
                <a href="mailto:nunamoving@gmail.com">nunamoving@gmail.com</a>
              </p>
            </div>
          </div>
        </Col>
        <Col className="right">
          <h5>REQUEST A QUOTE</h5>
          <Form
            name="basic"
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            form={form}
            autoComplete="off"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            {/* Form Items */}
            <Row>
              <Col>
                <Form.Item
                  label="Name"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input className="custom_input" />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="useremail"
                  label="Email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid Email!",
                    },
                    {
                      required: true,
                      message: "Please input your Email!",
                    },
                  ]}
                >
                  <Input className="custom_input" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Item
                  label="Contact No."
                  name="usermobile"
                  rules={[
                    {
                      required: true,
                      message: "Please input your mobile number!",
                    },
                  ]}
                >
                  <Input className="custom_input" />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Subject"
                  name="subject"
                  rules={[
                    {
                      required: true,
                      message: "Please input the subject!",
                    },
                  ]}
                >
                  <Input className="custom_input" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item
                  label="Service"
                  name="service"
                  rules={[
                    {
                      required: true,
                      message: "Please select a service!",
                    },
                  ]}
                >
                  <Select
                    // placeholder="Select a service"
                    onChange={handleServiceSelect}
                  >
                    {service &&
                      service?.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item
                  label="Message"
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "Please input your message!",
                    },
                  ]}
                >
                  <TextArea
                    // placeholder="Message"
                    autoSize={{ minRows: 5, maxRows: 5 }}
                    className="custom_input"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="button_theme"
                    >
                      {loading && <Spin size="small" />}
                    SUBMIT
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
export default HomeContact;
