import React, { useState } from 'react';
import { BiSupport } from "react-icons/bi";
import "./GlobalChatButton.scss"
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { getLocalStorageItem } from '../Constants/Api/locastorageHandle';
const GlobalChatButton = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const token = getLocalStorageItem("UserLoginToken");
  const handleOpenModal = () => {
    setShow(false)
    if (token) {
      navigate("/support")
    } else {
      setShow(true);
    }
  };
  const handleLogin = () => {
    navigate("/signin");
    handleClose()
  };
  const handleSignup = () => {
    navigate("/signup");
    handleClose()
  };
  return (
    <>
      <button className="chatButton" onClick={handleOpenModal}>
        <BiSupport size={70} className='icon' title="Support" />
      </button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>"Welcome to our support chat! To better assist you, please log in to your account. This will allow us to access your account details and provide personalized support. If you don't have an account yet,Please Create your Account <span style={{color:"blue",textDecorationLine:"underline",cursor:"pointer"}} onClick={handleSignup}>Sign-Up</span>Thank you!"

          {/* "Welcome to our support chat! To access our services and receive personalized assistance, please log in to your account or sign up if you're new here. If you're experiencing any difficulties with logging in or signing up, feel free to let us know, and we'll be happy to assist you. Thank you for choosing us for your support needs!" */}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleLogin}>
            Login
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default GlobalChatButton;
