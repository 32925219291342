import React, { useEffect, useState } from "react";
import CardComponent from "../../Component/CardComponent/CardComponent";
import "./Servies.scss"
import { Container } from "react-bootstrap";
import { GetServiceNameAPI } from "../../Constants/Api/ServicesApi/ServicesApi";

function ServicesConatiners() {
    const [service, setServices] = useState()
    useEffect(() => {
        GetServiceNameAPI().then((res) => {
            setServices(res?.data?.data)
        }).catch((error) => {
        })
    }, [])
    return (
        <Container className="servies_containerr_main">
            <div class="row row-cols-1 row-cols-md-4 g-4">
                {service?.map((item, index) => {
                    return (
                        <div class="col" key={index}>
                            <CardComponent CardImage="" Name={item?.name} subName="MOVING" background="true" data={item} />
                        </div>
                    )
                }
                )}
               
            </div>
        </Container>
    );
}
export default ServicesConatiners;
