import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Skeleton } from "antd";
import "./ServiceDetails.scss";

import { Image_URL } from "../../Constants/host";
import BookingComponent from "../BookingComponent/BookingComponent";
import BookingConfirmationModal from "./BookingConfirmationModal"; // Import the new modal
import { UserLoginAsGuestAPI } from "../../Constants/Api/AuthApi/AuthApi";
import { ServiceDetailsAPI } from "../../Constants/Api/ServicesApi/ServicesApi";
import { getLocalStorageItem, removeLocalStorageItem } from "../../Constants/Api/locastorageHandle";

const ServiceDetails = () => {
  const location = useLocation();
  const { data } = location.state || {};
  const [serviceDeatils, setServiceDetails] = useState();
  const [isBookingModalVisible, setBookingModalVisible] = useState(false);
  const [isConfirmationModalVisible, setConfirmationModalVisible] =
    useState(false); // State for confirmation modal
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const formData = {
      name: data?.slug,
    };
    console.log(formData, "formData");
    ServiceDetailsAPI(formData)
      .then((res) => {
        console.log(res, "ress");
        setLoading(false);
        setServiceDetails(res.data.data);
      })
      .catch((error) => {
        // Handle error
      });
  }, [data?.slug]);

  const handleBookButtonClick = () => {
    // Open the confirmation modal
    setConfirmationModalVisible(true);
  };

  const handleBookingModalClose = () => {
    setBookingModalVisible(false);
  };

  const handleConfirmationModalClose = () => {
    setConfirmationModalVisible(false);
  };

  const handleGuestClick = () => {
    handleLoginASGuest(); // Call the guest login function
    setBookingModalVisible(true); // Open the booking modal
    setConfirmationModalVisible(false); // Close the confirmation modal
  };

  const handleLoginClick = () => {
    // Logic to redirect to login page or open login modal
    navigate("/signin"); // Assuming you have a login route
    setConfirmationModalVisible(false); // Close the confirmation modal
  };

  const navigate = useNavigate();

  const handleLoginASGuest = () => {
    UserLoginAsGuestAPI()
      .then((res) => {
        setTimeout(() => {
          const token = res.data.data.tokens.access.token;
          removeLocalStorageItem("GuestLoginToken");
          localStorage.setItem("GuestLoginToken", token);
          removeLocalStorageItem("Userguest_id");
          localStorage.setItem("Userguest_id", "GuestLogin");
        }, 1000);
      })
      .catch((error) => {
        // Handle error
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  return (
    <div className="ServiceDetails">
      <Container className="ServiceContent">
        <Row className="Section">
          <Col className="left" sm={6}>
            <img
              src={`${Image_URL}/${data?.service_attachments[0]?.file_name}`}
              crossOrigin="anonymous"
              alt=""
            />
          </Col>
          <Col className="right" sm={6}>
            {loading ? (
              <Skeleton active />
            ) : (
              <>
                <h5>{serviceDeatils?.name}</h5>
                <button
                  className="buttontheme2"
                  onClick={handleBookButtonClick}
                >
                  BOOK US
                </button>
                <BookingComponent
                  isVisible={isBookingModalVisible}
                  onClose={handleBookingModalClose}
                  serviceID={data?.id}
                />
                <BookingConfirmationModal
                  isVisible={isConfirmationModalVisible}
                  onClose={handleConfirmationModalClose}
                  onGuestClick={handleGuestClick}
                  onLoginClick={handleLoginClick}
                />
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: serviceDeatils?.description,
                  }}
                ></div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ServiceDetails;
