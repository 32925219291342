// reducers/index.js
import { combineReducers } from 'redux';
import cartReducer from './cartSlice'
import chatReducer from "./chatSlice"
import getClientProfileReducer from "./getClientProfileSlice"
const rootReducer = combineReducers({
  cartReducerData: cartReducer,
  chat: chatReducer,
  clientProfile: getClientProfileReducer,

});
export default rootReducer;
