import logo from "./logo.svg";
import "./App.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HeaderSection from "./Component/HeaderSection/HeaderSection";
import GlobalChatButton from "./Component/GlobalChatButton";
import WebRoutes from "./WebRoutes";
import FooterSection from "./Component/FooterSection/FooterSection";
function App() {
  return (
    <div>
      <HeaderSection />
      <WebRoutes />
      <GlobalChatButton />
      <FooterSection />
    </div>
  );
}
export default App;
