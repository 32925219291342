import React from "react";
import "./Aboutus.scss";
import Truck from "../../assets/truck.png";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const AboutUs = () => {
  const navigate = useNavigate();
  const handleredirectAboutUs = () => {
    navigate("/about-us");
  };
  return (
    <div className="Aboutus">
      <Container>
        <div className="row AboutUsSectionInner">
          <div className=" col-md-5 col-sm-5 col-xs-12 left">
            <h1>About us</h1>
            <p>
              On November 2015, Nunavut Moving &amp; services turn{" "}
              <strong>ONE YEAR OLD</strong>, Yes we are a young compagny with
              the ONLY goal to satisfy <strong>OUR VALUABLE CLIENTS</strong>. We
              are now taking a step back to reflect and share with you, our
              customers, how far we have come. Nunavut Moving &amp; Services was
              created with the sole purpose of solving moving services in
              Iqaluit, Nunavut; we planned to change the perception of the
              delivery and moving industry. We always knew we wanted to be
              different ...
            </p>
            <Button
              className="buttontheme"
              variant="primary"
              onClick={handleredirectAboutUs}
            >
              Read More
            </Button>
          </div>
          <div className="col-md-7 col-sm-7 col-xs-12 right">
            <img src={Truck} alt="" />
          </div>
        </div>
      </Container>
    </div>
  );
};
export default AboutUs;
