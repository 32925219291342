export const removeLocalStorageItem = (key) => {
  if (typeof window !== "undefined") {
    return localStorage.removeItem(key);
  }
  return null;
};

export const getLocalStorageItem = (key) => {
  if (typeof window !== "undefined") {
    return localStorage.getItem(key);
    // return null
  }
  return null;
};
