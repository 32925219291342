import React from "react";
import "./DeleteAccount.scss";
import { Container } from "react-bootstrap";
import { LuDot } from "react-icons/lu";
import { GoDotFill } from "react-icons/go";
const DeleteAccount = () => {
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
}, [])
  return (
    <div className="DeleteAccount">
      <Container>
        <p>
          At Nunavut Moving and Services, we are committed to safeguarding your
          privacy and ensuring that your data is handled securely. If you decide
          to delete your account, please follow the detailed steps below. This
          process will result in the permanent deletion of all your personal
          data from our system.
        </p>
        <h6>Steps to Delete Your Account:</h6>
        <ul>
          <strong>
            <span>1.</span> Log In to Your Account:
          </strong>
          <li>
            Open the Nunavut Moving and Services app on your mobile device.
          </li>
          <li>
            Enter your login credentials (email and password) to access your
            account.
          </li>
        </ul>
        <ul>
          <strong>
            <span>2.</span> Navigate to Account Settings:
          </strong>
          <li>
            Once you are logged in, locate the account settings. This is usually
            found by tapping on the profile icon or the menu button in the app.
          </li>
        </ul>
        <ul>
          <strong>
            <span>3.</span> Select &#39;Profile&#39;:
          </strong>
          <li>
            In the account settings menu, tap on &#39;Profile&#39;. This section
            contains your personal information and account management options.
          </li>
        </ul>
        <ul>
          <strong>
            <span>4.</span> Delete Account:
          </strong>
          <li>
            On the left side of the &#39;Profile&#39; page, you will find the
            &#39;Delete Account&#39; option. Tap on it to initiate the deletion
            process.
          </li>
        </ul>{" "}
        <ul>
          <strong>
            <span>3.</span> Confirm Deletion:
          </strong>
          <li>
            You will be prompted to confirm your decision to delete your
            account. Please read the instructions carefully.
          </li>
          <li>
            Confirm the deletion by tapping on the &#39;Delete&#39; button
            again. This action is irreversible, and once confirmed, your account
            will be permanently deleted.
          </li>
        </ul>
        <h6>Data Deletion Policy:</h6>
        <ul>
          <strong><GoDotFill size={10} /> Data Deleted:</strong>
          <li>
            Upon deletion of your account, all your personal data will be
            permanently removed from our system. This includes:
            <li>Your personal information (name, address, contact details)</li>
            <li>
              Job history and records of any moving services you have booked
            </li>
            <li>Any settings or preferences you have configured in the app</li>
            <li>Communication history with our support team</li>
          </li>
        </ul>
        <ul>
          <strong><GoDotFill size={10} /> Data Retained:</strong>
          <li>
            <strong>No Data Retained:</strong> We do not retain any of your
            personal data after you delete your account. All information
            associated with your account will be completely erased from our
            servers.
          </li>
        </ul>
        <ul>
          <strong><GoDotFill size={10} /> Retention Period:</strong>
          <li>
            <strong>Immediate Deletion:</strong> The deletion of your data is
            immediate upon confirmation of account deletion. There is no
            additional retention period for any data once the account is
            deleted.
          </li>
        </ul>
      </Container>
    </div>
  );
};
export default DeleteAccount;
