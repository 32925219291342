// auth.js

import apiClient from "../apiClient";

export const UserLoginAPI = async (formdata, headers) => {
  return await apiClient.post("user/auth/login", formdata, headers);
};

export const UserLoginAsGuestAPI = async () => {
  return await apiClient.post("user/auth/guestLogin", null);
};

export const UserRegisterAPI = async (formdata) => {
  return await apiClient.post("user/auth/register", formdata);
};

export const SendOTPAPI = async (formdata) => {
  return await apiClient.post("user/auth/otp", formdata);
};

export const VerifyOTPAPI = async (formdata) => {
  return await apiClient.post("user/auth/verify-otp", formdata);
};

export const ForgotPasswordApi = async (formdata) => {
  return await apiClient.post("user/auth/forgot-password", formdata);
};

export const ChangePasswordAPI = async (formData) => {
  return await apiClient.post("user/auth/reset-password", formData);
};
