import { Skeleton } from "@mui/material";
import React from "react";

const SkeletonCard = () => {
  return (
    <>
      {Array.from({ length: 5 }).map((_, index) => (
        <div className="col" key={index}>
          <div className="card">
            <Skeleton animation="wave" variant="rectangular" height={150} />
            <div className="card-body">
              <Skeleton animation="wave" height={20} width="80%" />
              <Skeleton animation="wave" height={20} width="60%" />
              <Skeleton animation="wave" height={20} width="40%" />
              <Skeleton animation="wave" height={20} width="40%" />
              <Skeleton animation="wave" height={20} width="40%" />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default SkeletonCard;
