import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import "./AboutUSComponent.scss"
const AboutUSComponent = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, [])
    return (
        <div className='AboutUSComponent'>
            <Container>
                <div>
                    <h5>About Us</h5>
                    <p>On November 2015, Nunavut Moving &amp; services turn <b>ONE YEAR OLD</b>, Yes we are a young compagny with the ONLY goal to satisfy <b>OUR VALUABLE CLIENTS</b>. We are now taking a step back to reflect and share with you, our customers, how far we have come. Nunavut Moving &amp; Services was created with the sole purpose of solving moving services in Iqaluit, Nunavut; we planned to change the perception of the delivery and moving industry. We always knew we wanted to be different. We made an informed decision to focus on you, our customers, thus our slogan “Your Satisfaction, is our Priority”. We know that there is a “dreary Taboo” associated to customer services in the moving industry. We bring to you a vibrant and positive attitude with our moving services. ONE year on, and over 500 moves in Iqauit and out of the territorry, we’re continuing to grow our team and better services, we continue to make moving fun and affordable for all.</p>
                </div>
                <div>
                    <h5>Our Philosophy</h5>
                    <p>We all agree that moving can be difficult. Whether you’re packing to move to a new home or trying to pick up a sofa you’ve bought at the store or cargo, organising a delivery can be time consuming and expensive. While all you want is to get someone to help you bring it to your Home as soon as possible. We are HERE for that and we understand the need to have your home ready and cozy.</p>
                </div>
                <div >
                    <h5>Our Mission</h5>
                    <p>Nunavut Moving &amp; Services was established to solve the major problems of the moving services in Iqaluit, specifically in regard to the time of delivery, the high price tag of moving, and the customer services How are we solving these problems?</p>
                    <p><b>1. Changing the meaning of Moving and make it easier</b><br /> We are continually empowering our customers with choices to provide them with an easy move. We want to be sure that you recive the best quote and affordable moving. We work with you. We use state of the art equipement to safely moving your furniture, appliances or office supplies in a reasonable time. Our equipment reduice the moving time and save you money.</p>
                    <p><b>2. Providing the highest level of customer service</b><br /> Nunavut Moving &amp; Services listens to you, Our customers. We are focused on creating a memorable and best customer experience, to our valuabe customers. Nunavut Moving &amp; Services is working hard to reach a 100% positive rating.</p>
                    <p><b>3. Reducing wait time for services</b><br /> Nunavut Moving &amp; Services works hard to accommodate your move, we will accomodate most move any time. We attend to your need as if it is ours, we do not make you wait to move in your new home nor do we make you wait for that furniture you have been waiting for to put more color in your home. We do not allow offices to wait to get their supplies for work. We DEFINITIVELY pride ourselves on our customer service, and you can rely on us to always be positive, responsive, friendly and reliable. It’’s Our Way and Our Call!</p>
                </div>


            </Container>
        </div>
    )
}

export default AboutUSComponent
