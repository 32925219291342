import React, { useCallback, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./Profile.scss";
import "react-phone-input-2/lib/style.css";
import { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getClinetProfile } from "../../redux/getClientProfileSlice";
import { DatePicker, Form, Input, message, Spin } from "antd";
import Signin from "../Signin/Signin";
import { Image_URL } from "../../Constants/host";
import {
  DeleteAccountApi,
  GetProfile,
  UserEditProfileAPI,
} from "../../Constants/Api/ServicesApi/ServicesApi";
import { ChangePasswordAPI } from "../../Constants/Api/AuthApi/AuthApi";
import { getLocalStorageItem } from "../../Constants/Api/locastorageHandle";
const ProfileComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [profile, setProfile] = useState();
  const [selectedImage, setSelectedImage] = useState("");
  const [name, setName] = useState();
  const [loading, setLoading] = useState(false);
  const [mobile, setMobile] = useState();
  const [value, setValue] = useState("edit");
  const [old_password, setOldPassword] = useState();
  const [new_password, setNewPassword] = useState();
  const [new_c_password, setNewCPassword] = useState();
  const [dateprofile, setDateprofile] = useState();
  const [alert, setAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    text: "",
    icon: "",
  });
  const [isClient, setIsClient] = useState(false);
  // useEffect(() => {
  //     setIsClient(true)
  // }, [])
  const handleValueChangeEdit = () => {
    setValue("edit");
  };
  const handleValueChangePassword = () => {
    setValue("change_password");
  };
  const handleDeleteAccount = () => {
    setValue("delete_account");
  };
  const handlePhoneChange = (value) => {
    setMobile(value);
  };

  const [profileData, setProfileData] = useState();
  useEffect(() => {
    // Set loading to true before making the API call
    setLoading(true);

    GetProfile()
      .then((res) => {
        console.log(res, "res in profile");
        setProfileData(res?.data?.data);
        form.setFieldValue("name", res?.data?.data?.user_profile?.name);
        form.setFieldValue("mobile", res?.data?.data?.user_profile?.mobile);
        form.setFieldValue("address", res?.data?.data?.user_profile?.address);
        form.setFieldValue("email", res?.data?.data?.email);
        form.setFieldValue("country", res?.data?.data?.user_profile?.country);
        setDateprofile(res?.data?.data?.user_profile?.dob);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        // Ensure loading is set to false whether the API call succeeds or fails
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);
  console.log(loading, "loading==>>");
  const onChange = (date, dateString) => {};
  const handleAlertSubmit1 = () => {
    const missingFields = [];
    if (!old_password) missingFields.push("Old Password");
    if (!new_password) missingFields.push("New Password");
    if (!new_c_password) missingFields.push("Confirm Password");
    if (missingFields.length > 0) {
      setAlert(true);
      setAlertConfig({
        icon: "error",
        text: `Please fill in the following required fields: ${missingFields.join(
          ", "
        )}.`,
      });
      setTimeout(() => {
        setAlert(false);
      }, 7000);
    } else {
      handleChangePassword();
    }
  };
  const handleCancel = () => {
    setNewPassword(undefined);
    setNewCPassword(undefined);
    setOldPassword(undefined);
    form.resetFields();
  };

  const handleSubmit = useCallback(() => {
    setAlert(false);
    const formData = new FormData();
    if (selectedImage) {
      formData.append("image", selectedImage, "profile");
    }
    formData.append("name", name || "");
    formData.append("mobile", mobile || "");
    formData.append("country", form.getFieldValue("country") || "");
    formData.append(
      "dob",
      form.getFieldValue("date")?.format("YYYY-MM-DD") || ""
    );
    formData.append("address", form.getFieldValue("address") || "");
    UserEditProfileAPI(formData)
      .then((res) => {
        message.success(res?.data?.message);
        setProfile(res.data);
        if (res.data.code === 200 || res.data.status === 200) {
          setAlert(true);
          setAlertConfig({
            text: "Profile Updated Successfully",
            icon: "success",
          });
          setTimeout(() => {
            dispatch(getClinetProfile());
            // navigate("/signin");
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch, form, selectedImage, name, mobile]);

  const handleChangePassword = () => {
    const formData = {
      old_password: old_password,
      new_password: new_password,
      confirm_password: new_c_password,
    };
    ChangePasswordAPI(formData)
      .then((res) => {
        if (res.data.code === 200 || res.data.status === 200) {
          message.success(res?.data?.message);
          setTimeout(() => {
            navigate("/signin");
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
        setAlert(true);
        setAlertConfig({
          text: error.response.data.message,
          icon: "error",
        });
      });
  };
  const handleAccountAccount = () => {
    DeleteAccountApi()
      .then((res) => {
        navigate("/");
        localStorage.removeItem("Userguest_id");
        localStorage.removeItem("UserLoginToken");
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  return (
    <>
      <Container className="profile">
        <div className="profile_inner">
          <div className="section1">
            <div>
              {profileData &&
              profileData?.user_attachments &&
              profileData?.user_attachments?.length > 0 ? (
                <img
                  src={`${Image_URL}${profileData?.user_attachments[0]?.file_name}`}
                  width="100px"
                  height="100px"
                  className="Picture"
                  alt=""
                  crossOrigin="anonymous"
                />
              ) : (
                <>
                  <img
                    src="https://img.freepik.com/premium-vector/anonymous-user-circle-icon-vector-illustration-flat-style-with-long-shadow_520826-1931.jpg?w=360"
                    width={100}
                    height={100}
                    className="Picture"
                    alt=""
                  />
                </>
              )}
            </div>
            <div>
              <h5 style={{ textTransform: "capitalize" }}>{profile?.name}</h5>
              <h6>Set up your Account.</h6>
            </div>
          </div>
          <div className="section2">
            <div className="btn_tab">
              <Button
                className="btn_theme_div"
                style={{
                  backgroundColor: value === "edit" ? "#174F78" : "#f0f4f7",
                  color: value === "edit" ? "white" : "unset",
                }}
                onClick={handleValueChangeEdit}
              >
                Edit
              </Button>
              <Button
                className="btn_theme_div"
                style={{
                  backgroundColor:
                    value === "change_password" ? "#174F78" : "#f0f4f7",
                  color: value === "change_password" ? "white" : "unset",
                }}
                onClick={handleValueChangePassword}
              >
                Change Password
              </Button>
              <Button
                className="btn_theme_div"
                style={{
                  backgroundColor:
                    value === "delete_account" ? "#174F78" : "#f0f4f7",
                  color: value === "delete_account" ? "white" : "unset",
                }}
                onClick={handleDeleteAccount}
              >
                Delete Account
              </Button>
            </div>
            <div className="line"></div>
            <div className="Form_ctm">
              {value === "edit" ? (
                <div>
                  <Form
                    form={form}
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                  >
                    <div className="profiletop">
                      {selectedImage === "" ? (
                        <img
                          src="https://img.freepik.com/premium-vector/anonymous-user-circle-icon-vector-illustration-flat-style-with-long-shadow_520826-1931.jpg?w=360"
                          alt=""
                          // height="50px"
                          // width="50px"
                          className="Profile_top_images"
                          style={{ borderRadius: "100px" }}
                        />
                      ) : (
                        selectedImage && (
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            className="Profile_top_images"
                            alt="Profile Pic"
                            style={{ borderRadius: "50px" }}
                          />
                        )
                      )}

                      <label
                        className="custom_file_upload"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          setSelectedImage(file);
                        }}
                      >
                        <input type="file" accept="image/*" name="image" />
                        &nbsp; Add Profile picture
                      </label>
                    </div>
                    <Form.Item
                      name="name"
                      label="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      rules={[
                        {
                          message: "Please input your Name!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name="email"
                      label="Email"
                      disabled="true"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid Email!",
                        },
                        {
                          message: "Please input your Email!",
                        },
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>
                    <Row>
                      <Col>
                        <Form.Item
                          label="Phone Number"
                          name="mobile"
                          rules={[
                            {
                              message: "Please input your phone number!",
                            },
                          ]}
                        >
                          <PhoneInput
                            country={"us"}
                            value={mobile}
                            onChange={handlePhoneChange}
                            disableDropdown={true}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item name="date" label="DOB">
                          <DatePicker
                            onChange={onChange}
                            placeholder={dateprofile}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      name="address"
                      label="Address"
                      rules={[
                        {
                          message: "Please input your Address!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter your address" />
                    </Form.Item>
                    <Form.Item
                      name="country"
                      label="Country"
                      rules={[
                        {
                          message: "Please input your Country!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter your country" />
                    </Form.Item>
                  </Form>
                  <div className="buttons">
                    <Button
                      className="buttontheme"
                      style={{ margin: "10px auto", width: "70%" }}
                      onClick={handleSubmit}
                    >
                      {loading && <Spin size="small" />} Update
                    </Button>
                  </div>
                </div>
              ) : value === "change_password" ? (
                <div>
                  <Form name="basic" layout="vertical" autoComplete="off">
                    <Form.Item
                      label="Old Password"
                      name="oldpassword"
                      value={old_password}
                      onChange={(e) => setOldPassword(e.target.value)}
                      rules={[
                        {
                          required: true,
                          message: "Please input your old password!",
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      label="New Password"
                      name="newpassword"
                      value={new_password}
                      onChange={(e) => setNewPassword(e.target.value)}
                      rules={[
                        {
                          required: true,
                          message: "Please input your new password!",
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      name="confirmpassword"
                      label="Confirm Password"
                      dependencies={["newpassword"]}
                      hasFeedback
                      onChange={(e) => setNewCPassword(e.target.value)}
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue("newpassword") === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The new password that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </Form>
                  <div className="buttons">
                    <Button
                      className="buttontheme"
                      style={{ margin: "10px 5px", width: "28%" }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="buttontheme"
                      style={{ margin: "10px 5px", width: "70%" }}
                      onClick={handleAlertSubmit1}
                    >
                      Change Password
                    </Button>
                  </div>
                </div>
              ) : (
                <Button
                  className="buttontheme"
                  style={{ margin: "10px 5px", width: "70%" }}
                  onClick={handleAccountAccount}
                >
                  Delete Account
                </Button>
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};
export default ProfileComponent;
