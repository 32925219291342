import React, { useEffect } from "react";
import CarouselComponent from "../../Component/Carousel/Carousel";
import ServicesConatiners from "../../Component/Servies/ServiesContainer";
import "./LandingPage.scss";
import HeadingImage from "../../assets/heading-border.png";
import Picture1 from "../../assets/section3_pic1.jpg";
import Picture2 from "../../assets/section3_pic2.jpg";
import Picture3 from "../../assets/section3_pic3.jpg";
import Picture4 from "../../assets/section3_pic4.jpg";
import HomeContact from "../../Component/HomeConatctuspage/HomeContact";
import BlogComponent from "../../Component/BlogComponent/BlogComponent";
import ReactPlayer from "react-player";
import Video from "../../assets/Nunamoving.mp4";
import AboutUs from "../../Component/Aboutus/Aboutus";
const LandingPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);
  return (
    <div className="landingPage">
      <div className="section1">
        <CarouselComponent />
      </div>
      <div className="section2">
        <ServicesConatiners />
      </div>
      <div className="section3">
     <AboutUs/>
      </div>
      <div className="section4">
        <h4 className="section4_heading">
          YOU PICK THE LOCATION, WE WILL TAKE CARE OF THE REST
        </h4>
        <br />
        <img src={HeadingImage} alt="" />
        <h5>
          CALL US TODAY TO GET A QUOTE
          <span className="color_white">(867) 979-0707</span>
        </h5>
      </div>
      <div className="section5">
        <div className="imgsection">
          <img src={Picture1} alt="" />
        </div>
        <div className="imgsection">
          <img src={Picture2} alt="" />
        </div>
        <div className="imgsection">
          <img src={Picture3} alt="" />
        </div>
        <div className="imgsection">
          <img src={Picture4} alt="" />
        </div>
      </div>
      <div className="section6">
        <HomeContact />
      </div>
      <div className="section7">
        <BlogComponent />
      </div>
      <div className="video-wrapper">
        <ReactPlayer url={Video} controls={true} width="100%" height="100%" />
      </div>
      <div className="section9">
        <div className="MapSection">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3529.047098862995!2d-68.535687!3d63.752049!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4dd3164431eff40d%3A0x416860c321d32f1!2sNunavut%20Moving%20%26%20Services!5e0!3m2!1sen!2sus!4v1705407307727!5m2!1sen!2sus"
            width="600"
            height="550"
            allowFullScreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            style={{ margin: "0" }}
          ></iframe>
        </div>
      </div>
    </div>
  );
};
export default LandingPage;
